import TalkSpecialistButton from '@comparacar/components/src/generic/TaskSpecialistButton'
import engineState from '@comparacar/components/src/state/engine'
import { iMainProps } from '@comparacar/lib/src/nextCommon/pages/assinar'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import React, { ReactElement } from 'react'
import { useSetRecoilState } from 'recoil'
export { getStaticProps } from '@comparacar/lib/src/nextCommon/pages/assinar'

import Layout from '../../template/Layout'
import Main from '../../template/Main'
import type { NextPageWithLayout } from '../_app'

const Overview: NextPageWithLayout<iMainProps> = ({ links, videoList, highlights }) => {
  const setEngine = useSetRecoilState(engineState)
  const theme = useTheme()

  setEngine('subscribe')

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          right: { xs: 16, sm: 24 },
          left: { xs: 16, sm: 'unset' },
          bottom: 24,
          fontSize: 48,
          zIndex: theme.zIndex.fab
        }}
      >
        <TalkSpecialistButton />
      </Box>
      <Main searchOption="subscribe" videoList={videoList} links={links} highlights={highlights} />
    </>
  )
}

Overview.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>
}

export default Overview
